import { getTabloData } from '~/utils/api';
import TabloRequest from '~/requests/tablo';
import TabloList from '~/entities/tablo-list';

export default {
  async asyncData ({ store, route }) {
    const tablo = await getTabloData({ store, route, request: new TabloRequest(true) });
    return { tablo };
  },

  data () {
    return {
      tablo: {},
      tabloRequest: null,
      tabloLoading: false,
    };
  },

  created () {
    this.tabloRequest = new TabloRequest(this.route === 'tablo');
  },

  methods: {
    async refreshTablo(day) {
      this.tabloLoading = true;
      this.tabloRequest.setDateByDay(day);

      this.tablo = await getTabloData({
        store: this.$store,
        route: this.$route,
        request: this.tabloRequest,
      });

      this.tabloLoading = false;
    },
    transformTablo (data) {
      return new TabloList(
        data,
        this.tabloRequest.timeOptions,
        this.tabloRequest.direction.altValue
      );
    },
  },
};
