
import {rzTabs, rzIconFlightStart, rzIconFlightEnd, rzSimpleTable, rzPreloader, rzAirlineIcon, rzButton, rzIconReturn} from 'razlet-ui';
import advertising from '../assets/img/rek.webp';
import baggage from '../assets/img/baggage.webp';
import fastTrack from '../assets/img/fast_track.webp';
import vip from '../assets/img/vip.webp';
import advertisingHover from '../assets/img/rek_hover.webp';
import baggageHover from '../assets/img/baggage_hover.webp';
import fastTrackHover from '../assets/img/fast_track_hover.webp';
import vipHover from '../assets/img/vip_hover.webp';

export default {
  name: 'main-block',
  components: {
    rzTabs,
    rzIconFlightStart,
    rzIconFlightEnd,
    rzSimpleTable,
    rzPreloader,
    rzAirlineIcon,
    rzIconReturn,
    rzButton,
  },
  props: {
    slides: {
      type: Array,
      required: true,
    },
  },
  data() {
    return {
      transitioning: false,
      timer: null,
      buttons: [
        [
          {
            code: 'advertising',
            label: 'Реклама в аэропорту',
            icon: advertising,
            iconHover: advertisingHover,
            route: 'partners/advertizing-airport',
          },
          {
            code: 'baggage',
            label: 'Отправка грузов',
            icon: baggage,
            iconHover: baggageHover,
            route: 'for-big-planes/shipment',
          },
        ],
        [
          {
            code: 'vip',
            label: 'Зал повышенной комфортности',
            icon: vip,
            iconHover: vipHover,
            route: 'lounge',
          },
          {
            code: 'fast-track',
            label: 'Fast track',
            icon: fastTrack,
            iconHover: fastTrackHover,
            route: 'for-passengers/fasttrack',
          },
        ],
      ],
      selectedSlideIndex: 0,
    };
  },
  computed: {
    currentSlide () {
      return this.slides[this.selectedSlideIndex];
    },
    currentSlideComponent () {
      return this.currentSlide.link ? 'a' : 'div';
    },
    currentSlideClasses () {
      return {
        'main-page__slider-link--none': !this.currentSlide.link?.length,
      };
    },
    isTitle () {
      return !!this.currentSlide.title;
    },
    nextSlideIndex () {
      return this.selectedSlideIndex < this.slides.length - 1
        ? this.selectedSlideIndex + 1
        : 0;
    },
    previousSlideIndex () {
      return this.selectedSlideIndex > 0
        ? this.selectedSlideIndex - 1
        : this.slides.length - 1;
    },
  },
  mounted() {
    this.setAutoNext();
    const sliderEl = this.$refs.slider;
    const self = this;
    document.addEventListener('swiped-right', function(e) {
      if (e.target === sliderEl || e.path[1] === sliderEl || e.path[5] === sliderEl) {
        self.nextSlide(self.previousSlideIndex);
      }
    });
    document.addEventListener('swiped-left', function(e) {
      if (e.target === sliderEl || e.path[1] === sliderEl || e.path[5] === sliderEl) {
        self.nextSlide(self.nextSlideIndex);
      }
    });
  },
  destroyed() {
    clearInterval(this.timer);
  },
  methods: {
    goTo(href) {
      window.open(href, '_blank');
    },
    setAutoNext() {
      this.clearSliderTimeout();
      this.timer = setInterval(() => this.nextSlide(this.nextSlideIndex), 5000);
    },
    clearSliderTimeout () {
      if (this.timer) clearInterval(this.timer);
    },
    nextSlide(index) {
      this.transitioning = true;
      requestAnimationFrame(() => {
        this.selectedSlideIndex = index;
        this.transitioning = false;
        this.setAutoNext();
      });
    },
  },
};
