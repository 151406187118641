/* eslint-disable */
export function stringDateToDate(date) {
    let dateObj;
    if (typeof date === 'string') {
      if (/^\d{4}-(0\d{1}|1[012])-([012]\d{1}|3[012])\s([01][0-9]|2[0-3]):[0-5][0-9]:[0-5][0-9]$/.test(date)) {
        dateObj = new Date(date.replace(/-/g, '/'));
      } else {
        dateObj = new Date(date);
      }
    } else {
      dateObj = date;
    }
    return dateObj;
}

export function upFirstLetter(string) {
	if (!string) return string;

	return string.charAt(0).toUpperCase() + string.slice(1).toLowerCase();
}

export function unixToString(unixTime) {
	const h = Math.floor(unixTime / 3600);
	const m = Math.floor(unixTime / 60 - h * 60);
	const fixedMin = m.toString().length === 1 ? `0${m}` : m;
	if (h === 0) return `${fixedMin} мин`;
	if (m === 0) return `${h}ч`;
	return `${h}ч ${fixedMin} мин`;
}


export function copyObject(object) {
	return JSON.parse(JSON.stringify(object));
}

export function consoleError(error) {
	// eslint-disable-next-line no-console
	console.error(error);
}

export function plural(count, wordFormsArray) {
	let wordIndex;
	if (count % 10 === 1 && count % 100 !== 11) {
		wordIndex = 0; // many
	} else if (count % 10 >= 2 && count % 10 <= 4 && (count % 100 < 10 || count % 100 >= 20)) {
		wordIndex = 1; // few
	} else {
		wordIndex = 2; // one
	}
	return wordFormsArray[wordIndex] || '';
}

export function compareDeepObject(a, b) {
	if (a === b) {
		return true;
	}
	if (a == null || typeof a !== 'object' || b == null && typeof b !== 'object') {
		return false;
	}

	let equal = true;

	Object.keys(a).forEach(key => {
		if (a !== null && b !== null && typeof a === 'object' && typeof b === 'object') {
			if (a.hasOwnProperty(key) && b.hasOwnProperty(key)) {
				if (!compareDeepObject(a[key], b[key])) {
					equal = false;
				}
			} else if (!a.hasOwnProperty(key) && !b.hasOwnProperty(key)) {
				equal = true;
			} else {
				equal = false;
			}

		} else if (a !== b) {
			equal = false;
		}

	});

	return equal;
}


export function compareArraysOfObjects(a, b) {
	let result = true;
	a.forEach((item, i) => {
		if (item && b[i] && !compareMessages(item, b[i])) {
			result = false;
		}
	});
	return result;
}


export function downloadFileFromUrl(url, name) {
	if (!url) return;
	const fileLink = document.createElement('a');
	fileLink.href = url;
	if (name) {
		fileLink.setAttribute('download', name);
	} else {
		const urlPathsArray = url.split('/');
		fileLink.setAttribute('download', urlPathsArray[urlPathsArray.length - 1]);
	}
	fileLink.setAttribute('target', '_blank');
	document.body.appendChild(fileLink);
	fileLink.click();
}

export function debounce(func, wait) {
	let call = null;
	return function() {
		if (call) clearTimeout(call);
		call = setTimeout(() => {
			func();
		}, wait);
	};
}

export function throttle(func, waitTime) {
	let isThrottling = false;
	let hasTrailingCall = false;
	let lastContext;

	const invokeFunction = context => {
		func.apply(context);
		isThrottling = true;

		setTimeout(() => {
			isThrottling = false;
			if (hasTrailingCall) {
				invokeFunction(lastContext);
				lastContext = undefined;
				hasTrailingCall = false;
			}
		}, waitTime);
	};

	function wrapper() {
		if (!isThrottling) {
			invokeFunction(this);
		} else {
			hasTrailingCall = true;
			lastContext = this;
		}
	}

	return wrapper;
}

export function splitTime(t) {
  const [h, m] = t.split(':');

  return {hours: h.charAt(0) === '0' ? Number(h.charAt(1)) : Number(h), minutes: m.charAt(0) === '0' ? Number(m.charAt(1)) : Number(m)};
}

export function splitTimeRange(r) {
  return r.split('-').map(t => splitTime(t));
}

export function isTimeInRange(time, range) {
  if (!time) return false;
  const timeObj = splitTime(time);
  const rangeArr = splitTimeRange(range);
  return (rangeArr[0].hours <= timeObj.hours && (timeObj.hours < rangeArr[1].hours || rangeArr[1].hours === 0)) || (timeObj.hours === rangeArr[1].hours && timeObj.minutes === 0);
}

export function firstZero(number, power) {
	const zeroCount = Math.floor(Math.log10(number)) + 1;
	for (let i=zeroCount; i < power; i++) {
		number = `0${number}`;
	}
	return number;
}
