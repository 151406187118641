import weekdays from '~/constants/weekdays';
import { firstZero } from '~/utils';

export default class ScheduleItem {
  item = null;

  dictionary = null;

  constructor(data) {
    this.item = data.schedule;
    this.dictionary = data.dictionary;
  }

  get flight() {
    return this.item.flights[0];
  }

  get company() {
    return this.flight.company;
  }

  get companyName() {
    return this.dictionary.companies[this.company].name;
  }

  get number() {
    return this.flight.number;
  }

  get fromCity() {
    return this.dictionary.cities[this.flight.fromCity].name;
  }

  get toCity() {
    return this.dictionary.cities[this.flight.toCity].name;
  }

  get fromAirport() {
    return this.dictionary.airports[this.flight.fromAirport].name;
  }

  get toAirport() {
    return this.dictionary.airports[this.flight.toAirport].name;
  }

  get from() {
    return `${this.fromCity}(${this.fromAirport})`;
  }

  get to() {
    return `${this.toCity}(${this.toAirport})`;
  }

  // eslint-disable-next-line class-methods-use-this
  get days() {
    return weekdays.map(weekday => {
      return {
        name: weekday.short,
        id: weekday.num,
        active: this.item.frequency.includes(String(weekday.num)),
      };
    });
  }

  get fromTime() {
    return this.flight.fromTime;
  }

  get toTime() {
    return this.flight.toTime;
  }

  get begin() {
    return this.convertDate(this.item.periodBegin);
  }

  get beginDate () {
    return new Date(this.item.periodBegin);
  }

  get endDate () {
    return new Date(this.item.periodEnd);
  }

  get end() {
    return this.convertDate(this.item.periodEnd);
  }

  get navigation() {
    return `${this.begin} - ${this.end}`;
  }

  getDirection (type) {
    return type === 'departure' ? this.to : this.from;
  }

  // eslint-disable-next-line class-methods-use-this
  convertDate(date) {
    const d = new Date(date);
    const day = d.getDate();
    const month = d.getMonth() + 1;
    const year = d.getFullYear();
    return `${firstZero(day, 2)}.${firstZero(month, 2)}.${year}`;
  }
};
