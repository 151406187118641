
import { getSuluPage, getSuluApi, handleResponse, fillSeo } from 'razlet-sulu-sdk/lib/utils/helpers';
import { getTabloData, getScheduleData } from '~/utils/api';
import tablo from '~/mixins/tablo';
import TabloRequest from '~/requests/tablo';
import MainBlock from '~/views/main-block';
import TabloBlock from '~/views/tablo-block';
import ScheduleBlock from '~/views/schedule-block';
import NewsBlock from '~/views/news-block';
import ScheduleEntity from '~/entities/schedule-item';

export default {
  name: 'index',

  layout: 'index',

  components: {
    MainBlock,
    TabloBlock,
    ScheduleBlock,
    NewsBlock,
  },

  mixins: [tablo],

  async asyncData ({ store, route, redirect, error }) {
    const results = await Promise.allSettled([
      getSuluPage({ store, route, error, redirect }),
      getSuluApi({ store, redirect, path: `news-list` }),
      getSuluApi({ store, redirect, path: `snippet-areas/gallery` }),
    ])
      .catch(e => error(e));

    const [result, news, gallery] = handleResponse(results, error);
    const tablo = await getTabloData({ store, route, request: new TabloRequest(false) });
    const schedule = await getScheduleData({ store });

    return {
      result,
      news,
      tablo,
      schedule,
      gallery: gallery?.content?.elements || [],
    };
  },

  data () {
    return {
      schedule: {},
      news: {},
      snippets: [],
      gallery: [],
      imagesUrls: [],
      scheduleLoading: false,
      tabloLoading: false,
    };
  },

  created () {
    this.transformSchedule(this.schedule);
  },

  methods: {
    async loadSchedule (type = 'departure') {
      this.scheduleLoading = true;
      const response = await getScheduleData({ store: this.$store, type });
      this.transformSchedule(response);
      this.scheduleLoading = false;
    },

    transformSchedule (data) {
      if (!data || !data.schedule) return;
      this.schedule = data.schedule.map(item => new ScheduleEntity({
        schedule: item,
        dictionary: data?.dictionaries || {}
      }));
    },
  },

  head() {
    const links = this.imagesUrls.map(url => ({
      rel: 'preload',
      as: 'image',
      href: `${process.env.suluURL}${url}`,
    }));

    return {
      link: links,
      ...fillSeo(this, this.result.seo),
    };
  },
};
