import TabloItem from './tablo-item';

export default class TabloList {
  isError = false;

  list = [];

  listByTime = [];

  constructor(res, timeOptions = [], direction = 'departure') {
    if (!res || !Object.keys(res).length || !res.timeTable) {
      this.isError = true;
      return;
    }

    this.list = res.timeTable.map(item => new TabloItem({tablo:item, dictionary: res.dictionaries}));
    this.listByTime = timeOptions.map((option, index) => index === timeOptions.length - 1
      ? this.list
      : this.filterByTime(option.label, direction)
    );

    if (timeOptions.length) {
      timeOptions.forEach((option, index) => {
        if (index === timeOptions.length - 1) this.listByTime.push(this.list);
        else this.listByTime.push(this.filterByTime(option.label, direction));
      });
    }
  }

  get isFull() {
    return this.list && this.list.length;
  }

  closeAll() {
    this.list.forEach(item => {
      item.opened = false;
    });
  }

  filterByTime(time, direction) {
    return this.list.filter(item => item[direction].checkTime(time));
  }
};