import TabloRequest from '~/requests/tablo';
import createSender from '~/api/inside-sender';

export async function getTabloData ({ store, route, request }) {
  if (!(request instanceof TabloRequest)) throw new Error('Incorrect request type');

  if (route.name === 'tablo' && route.query) {
    request.fillFilter(route.query);
  }

  const { host } = store.state.sulu;
  const about = store.getters['sulu/airport/about'];
  const sender = createSender(host);
  const reqParams = request.buildRequest(about.code);

  try {
    const { data } = await sender.get('/asb/flights', { params: reqParams });
    return data;
  } catch (error) {
    return {};
  }
};

export async function getScheduleData ({ store, type }) {
  const { host } = store.state.sulu;
  const sender = createSender(host);

  try {
    const { data } = await sender.get('/asb/schedule', { params: { type }});
    return data;
  } catch (error) {
    return {};
  }
}
