
import {rzButton} from 'razlet-ui';
import {stringDateToDate} from '~/utils';

export default {
  name: 'news-card',
  components: {
    rzButton,
  },
  filters: {
    dateFormat(value) {
      const date = stringDateToDate(value);
      const day = String(date.getDate());
      const month = String(date.getMonth() + 1);

      const displayDay = day.length < 2 ? `0${day}` : day;
      const displayMonth = month.length < 2 ? `0${month}` : month;
      return `${displayDay}.${displayMonth}.${date.getFullYear()}`;
    },
  },
  props: {
    item: {
      type: Object,
      required: true,
    },
    full: {
      type: Boolean,
      default: false,
    },
    small: Boolean,
  },
  data() {
    return {
      isShow: true,
    };
  },
};
